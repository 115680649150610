const styles = () => {
  return {
    '.address-entity': {
      '&.address-entity_highlighted': {
        _before: {
          content: `" "`,
          position: 'absolute',
          py: 1,
          pl: 1,
          pr: 0,
          top: '-5px',
          left: '-5px',
          width: `100%`,
          height: '100%',
          borderRadius: 'base',
          borderColor: 'green.300',
          borderWidth: '1px',
          borderStyle: 'solid',
          zIndex: -1,
        },
      },
    },
    '.address-entity_no-copy': {
      '&.address-entity_highlighted': {
        _before: {
          pr: 2,
        },
      },
    },
  };
};

export default styles;
